import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	PlatformEnvDataProvider,
	SiteFeatureConfigSymbol,
	IMultilingual,
	MultilingualSiteConfig,
} from '@wix/thunderbolt-symbols'

import { name, MultilingualSetCurrentLanguageSymbol } from './symbols'
import { ISetCurrentLanguageAPI } from './types'

const multilingualFactory = (
	siteFeatureConfig: MultilingualSiteConfig,
	SetCurrentLanguageAPI: ISetCurrentLanguageAPI
): IMultilingual & PlatformEnvDataProvider => {
	const { isEnabled, isOriginalLanguage, siteLanguages, currentLanguage } = siteFeatureConfig
	const { setCurrentLanguage } = SetCurrentLanguageAPI

	return {
		platformEnvData: {
			multilingual: {
				isEnabled,
				isOriginalLanguage,
				siteLanguages,
				currentLanguage,
			},
		},
		isEnabled,
		isOriginalLanguage,
		siteLanguages,
		currentLanguage,
		setCurrentLanguage,
	}
}

export const Multilingual = withDependencies(
	[named(SiteFeatureConfigSymbol, name), MultilingualSetCurrentLanguageSymbol],
	multilingualFactory
)
