import type { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { Multilingual } from './multilingual'
import { MultilingualLinkUtilsAPISymbol, MultilingualSetCurrentLanguageSymbol, MultilingualSymbol } from './symbols'
import { PlatformEvnDataProviderSymbol, TpaSrcQueryParamProviderSymbol } from '@wix/thunderbolt-symbols'
import { MultilingualLinkUtilsAPI } from './multilingualLinkUtilsAPI'
import { MultilingualTpaSrcQueryParamProvider } from './tpaSrcQueryParamProvider'
import { SetCurrentLanguage } from './setCurrentLanguage'

export const site: ContainerModuleLoader = (bind) => {
	bind(MultilingualSymbol, PlatformEvnDataProviderSymbol).to(Multilingual)
	bind(MultilingualLinkUtilsAPISymbol).to(MultilingualLinkUtilsAPI)
	bind(TpaSrcQueryParamProviderSymbol).to(MultilingualTpaSrcQueryParamProvider)
	bind(MultilingualSetCurrentLanguageSymbol).to(SetCurrentLanguage)
}

export const editor: ContainerModuleLoader = (bind) => {
	bind(MultilingualSymbol, PlatformEvnDataProviderSymbol).to(Multilingual)
	bind(MultilingualSetCurrentLanguageSymbol).to(SetCurrentLanguage)
}

export * from './symbols'
export * from './types'
